import React from 'react';
import styled from '@emotion/styled';

import Image from '@components/Image';

import mediaqueries from '@styles/media';
import {IAuthor} from '@types';

import SocialLinks from '@components/SocialLinks';

interface AuthorHeroProps {
  author: IAuthor;
}

const Link = styled('a')`
  color: ${p => p.theme.colors.accent}
`

const AuthorHero: React.FC<AuthorHeroProps> = ({author}) => {
  return (
    <Hero>
      <HeroImage>
        <RoundedImage src={author.avatar.large} />
      </HeroImage>
      <Heading>{author.name}</Heading>
      <Subheading>{author.bio}</Subheading>
      <Social>
        <SocialLinks links={author.social} />
      </Social>
      <div>
      <br/>
      <br/>
        <Text>
          <b>
            My prefered form of contact is{' '}
            <Link href="https://twitter.com/yukatapangolin">twitter</Link> (I check it every few days).
          </b>
        </Text>
        <br /><br /><br />
        <hr/>
        <h2 style={{fontSize: "140%"}}>
          <Link href="https://get.wire.com">Wire</Link>
        </h2>
        <br />
        <Text>
        <em style={{fontStyle: 'italic'}}>
          When using Wire, the fact that we contacted each other is{' '}
          <Link href="https://motherboard.vice.com/en_us/article/gvzw5x/secure-messaging-app-wire-stores-everyone-youve-ever-contacted-in-plain-text">
            not encrypted
          </Link>
          . Here are{' '}
          <Link href="https://medium.com/@wireapp/staying-anonymous-on-wire-22faa13aba4d">
            instructions on how to stay more anonymous when using Wire
          </Link>
        </em>
        </Text>
        <br />
        <br />
        <Text>username: @glyptodontid</Text>
        <br />
        <br />
        <pre style={{background: '#eee', fontFamily: 'Consolas'}}>{`
KEY FINGERPRINT
bd 7a de 57 a6 ef ac 15 43 a8 ee d6 e9 24 f3 2c
62 bb 46 09 8b ed 8f 01 3a 96 57 8a fc 10 12 10
`}</pre>
        <br />
        <br /> <br /> <br />

        <hr/>
        <h2 style={{fontSize: "140%"}}>Minisign</h2> <br />
        <Text>
        If you ever need to verify my identity you can use this public key:
        </Text>
        <br /> <br />
        <pre style={{background: '#eee', fontFamily: 'Consolas'}}>{`
RWTGuzAVDo4xdv6KWGVeILeUblgltLeA863iP7NS3g4G9W25iiVu9V5f
`}</pre>
        <br />
        <br /> <br /> <br />

        <hr/>
        <h2 style={{fontSize: "140%"}}>Keybase</h2> <br />
        <br />
        <Link href="https://keybase.io/yucatapangolin">yucatapangolin</Link>
             </div>
    </Hero>
  );
};

export default AuthorHero;

const Hero = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 35px auto 110px;
`;

const Text = styled.div<{
  reverse: boolean;
  gridLayout: string;
  hasOnlyOneArticle: boolean;
}>`
  color: ${p => p.theme.colors.primary};
  font-family: "Goldman Sans";
  font-size: 120%
`;

const AContainer = styled.a`
  color: ${p => p.theme.colors.accent};
`;


const HeroImage = styled.div`
  position: relative;
  z-index: 1;
  height: 164px;
  width: 164px;
  margin-bottom: 35px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid ${(p) => p.theme.colors.background};
  box-shadow: 0px 15.619px 31.2381px rgba(0, 0, 0, 0.15);

  ${mediaqueries.tablet`
    width: 146px;
    height: 146px;
  `}

  ${mediaqueries.phablet`
    width: 136px;
    height: 136px;
    margin-bottom: 25px;
  `}
`;

const RoundedImage = styled(Image)`
  border-radius: 50%;
`;

const Heading = styled.h1`
  font-size: 38px;
  font-family: ${(p) => p.theme.fonts.sansSerif};
  color: ${(p) => p.theme.colors.primary};
  margin-bottom: 15px;
  font-weight: 600;

  ${mediaqueries.tablet`
  `}

  ${mediaqueries.phablet`
  `}
`;

const Subheading = styled.p`
  margin: 0 auto;
  max-width: 450px;
  color: ${(p) => p.theme.colors.grey};
  font-size: 18px;
  font-family: ${(p) => p.theme.fonts.sansSerif};
  line-height: 1.4;
  text-align: center;

  ${mediaqueries.phablet`
    font-size: 14px;
  `}
`;

const Social = styled.div`
  display: flex;
  align-items: center;
  margin-top: 35px;

  ${mediaqueries.phablet`
    font-size: 14px;
  `}
`;
